<template>
  <div>
    <div id="toolbar-component" ref="menuContainer">
      <div
        id="sidebar-btn"
        class="sidebar-btn noprint"
        :class="{ 'hidden-sidebar-btn': !isLoged }"
      >
        <!-- v-closable="{
          exclude: ['sidebarCollapse'],
          handler: 'onClose',
        }" -->
        <!-- v-b-tooltip.v-secondary.noninteractive.hover.right="collapse_sidebar?'Contraer Menú Lateral':'Expandir Menú Lateral'" -->
        <b-button
          v-if="!collapse_sidebar && user && !user.groups.includes(9)"
          id="sidebarCollapse"
          ref="sidebarCollapse"
          variant="none"
          size="sm"
          class="btn-collapse"
          @click="sidebarOpen"
        >
          <b-icon
            class="sidebar-btn-icon"
            icon="caret-right-fill"
            scale="1.2"
          ></b-icon>
        </b-button>
        <!-- <b-tooltip
        v-if="collapse_sidebar"
        variant="secondary"
        placement="topright"
        :noninteractive="true"
        boundary="document"
        triggers="hover"
        target="sidebar-btn"
        >Contraer</b-tooltip
        >
        <b-tooltip
        v-else
          variant="secondary"
          placement="top"
          :noninteractive="true"
          boundary="document"
          triggers="hover"
          target="sidebar-btn"
          >Expandir</b-tooltip
        > -->
      </div>
      <b-sidebar
        id="sidebar-left"
        text-variant="light"
        class="b-sidebar-left"
        left
        shadow
        z-index="1051"
        width="250px"
        no-header
        :backdrop="true"
        backdrop-variant="none"
        v-model="collapse_sidebar"
      >
        <!-- Body del Sidebar -->
        <nav id="sidebar" class="sidebar" v-if="user">
          <div
            class="sidebar-header"
            v-if="
              [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
              this.user.is_superuser
            "
          >
            <h3 class="mb-0 pb-0">Menú</h3>
            <div class="container-btn-inside-sidebar">
              <b-button
                v-if="collapse_sidebar"
                id="sidebarCollapse"
                ref="sidebarCollapse"
                variant="none"
                size="sm"
                class="btn-collapse2"
                @click="sidebarOpen"
              >
                <b-icon
                  class="sidebar-btn-icon"
                  icon="caret-right-fill"
                  rotate="180"
                  scale="1.2"
                ></b-icon>
              </b-button>
            </div>
          </div>
          <template ref="toolbar-links">
            <ul
              class="list-unstyled components"
              v-if="[1].includes(this.user.groups[0]) || this.user.is_superuser"
            >
              <p>Gestión Administrativa</p>
              <li v-if="this.user.is_superuser">
                <b-link :to="{ name: 'AdminUsersView' }">
                  <b-icon-book class="mr-2"></b-icon-book>Administrar Usuarios
                </b-link>
              </li>
              <li
                v-if="
                  institution &&
                  institution.kl_assessment &&
                  ([1].includes(this.user.groups[0]) || this.user.is_superuser)
                "
              >
                <b-link :to="{ name: 'SectionView' }">
                  <b-icon-book class="mr-2"></b-icon-book
                  >{{
                    $getVisibleNames(
                      "manual.administrar_seccion",
                      true,
                      "Administrar Secciones"
                    )
                  }}
                </b-link>
              </li>
              <li
                v-if="
                  institution &&
                  institution.kl_assessment &&
                  ([1].includes(this.user.groups[0]) || this.user.is_superuser)
                "
              >
                <b-link :to="{ name: 'ManageEvaluationsView' }">
                  <b-icon-book class="mr-2"></b-icon-book>Administrar
                  Evaluaciones
                </b-link>
              </li>
              <!-- Administrar Secciones -->
            </ul>
            <!-- TODO: Se comento mientras se trabaja en las Evaluaciones. -->
            <!-- <ul
            v-if="
              matters.length > 0 &&
              ([6].includes(this.user.groups[0]) || this.user.is_superuser)
            "
            class="list-unstyled components"
          >
            <p>Tablero Docente</p>
            <li v-for="matter in matters" :key="matter.id">
              <b-link
                :to="{
                  name: 'Dashboard',
                  params: {
                    matter_id: matter.egress_profile_matter_id,
                    tab_index: 0,
                    section_id: 0,
                    evaluation_id: 0,
                  },
                }"
                :id="`b-link-${matter.id}`"
              >
                <b-icon-book></b-icon-book>
                {{ matter.name }}
              </b-link>
              <b-tooltip
                class="hint"
                variant="secondary"
                triggers="hover"
                delay="50"
                :target="`b-link-${matter.id}`"
                >{{ getNameCareer(matter.career) }}</b-tooltip
              >
            </li>
          </ul> -->
            <!-- <ul
            v-if="
              next_evaluations.length > 0 &&
              ([7].includes(this.user.groups[0]) ||
                this.user.is_superuser ||
                this.user.is_student)
            "
            class="list-unstyled components"
          >
            <p>Próximas Evaluaciones</p>
            <li
              v-for="evaluation in next_evaluations"
              :key="evaluation.id"
              :title="evaluation.description"
            >
              <router-link
                :to="{
                  name: 'AnswerView',
                  params: { evaluation_id: evaluation.id },
                  force: true,
                }"
              >
                {{ evaluation.start_date | toDate }} -
                {{ evaluation.name }}
              </router-link>
            </li>
          </ul> -->

            <ul
              class="list-unstyled components"
              v-if="
                [1, 2, 3, 4, 5, 6].includes(this.user.groups[0]) ||
                this.user.is_superuser
              "
            >
              <p>
                <!-- Gestión Curricular y Pedagógica -->
                {{
                  $getVisibleNames(
                    "manual.gestion_curricular_y_pedagogica",
                    false,
                    "Gestión Curricular y Pedagógica"
                  )
                }}
              </p>
              <!-- <li
              v-if="
                [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
                this.user.is_superuser
              "
            >
              <b-link :to="{ name: 'CareerListView' }">
                <b-icon-book class="mr-1"></b-icon-book
                >
                 {{
                  $getVisibleNames(
                    "manual.recurso_curricular",
                    true,
                    "Recursos Curriculares"
                  )
                }}
                Programas
              </b-link>
            </li> -->
              <li
                v-if="
                  [1, 2, 3, 4, 5, 6].includes(this.user.groups[0]) ||
                  this.user.is_superuser
                "
              >
                <b-link :to="{ name: 'ActsView' }">
                  <b-icon-book class="mr-2"></b-icon-book
                  >{{
                    $getVisibleNames(
                      "manual.acta_de_comite",
                      true,
                      "Actas de Comités"
                    )
                  }}
                  <!-- Actas de Comités -->
                </b-link>
              </li>
              <!-- Reportes -->
              <!-- Vista Antigua de Reportes -->
              <!-- <li
              v-if="
                [1, 2, 3, 4, 5].includes(this.user.groups[0]) ||
                this.user.is_superuser
              "
            >
              <b-link :to="{ name: 'ReportsView' }">
                <b-icon-book class="mr-2"></b-icon-book
                >{{ $getVisibleNames("manual.reporte", true, "Reportes") }}
              </b-link>
            </li> -->
              <!-- Vista Nueva de Reportes -->
              <li
                v-if="
                  ([1, 2, 3, 4, 5].includes(this.user.groups[0]) ||
                    this.user.is_superuser) &&
                  institution &&
                  institution.kl_assessment &&
                  (institution.internal_use_id == 'ciisa_uss' ||
                    institution.internal_use_id == 'duoc_uc' ||
                    institution.show_kl_reports)
                "
              >
                <b-link :to="{ name: 'GeneralReport' }">
                  <b-icon-book class="mr-2"></b-icon-book
                  >{{ $getVisibleNames("manual.reporte", true, "Reportes") }}
                </b-link>
              </li>
              <!-- <li
              v-if="
                [1, 2, 3, 4, 5].includes(this.user.groups[0]) ||
                this.user.is_superuser
              "
            >
              <b-link :to="{ name: 'AdminUsersView' }">
                <b-icon-book class="mr-1"></b-icon-book>
                Usuarios
              </b-link>
            </li> -->
            </ul>
            <ul
              class="list-unstyled components"
              v-if="
                [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
                this.user.is_superuser
              "
            >
              <p>
                {{
                  $getVisibleNames(
                    "manual.recurso_metodologico",
                    true,
                    "Recursos Metodológicos"
                  )
                }}
                <!-- Recursos Metodológicos -->
              </p>
              <li
                v-if="
                  [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
                  this.user.is_superuser
                "
              >
                <b-link :to="{ name: 'MethodologicalStrategyView' }">
                  <b-icon-book class="mr-2"></b-icon-book
                  >{{
                    $getVisibleNames(
                      "mesh.methodologicalstrategy",
                      true,
                      "Estrategias Metodológicas"
                    )
                  }}
                </b-link>
              </li>
              <li
                v-if="
                  [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
                  this.user.is_superuser
                "
              >
                <b-link :to="{ name: 'DirectTeachingActivityView' }">
                  <b-icon-book class="mr-1"></b-icon-book>
                  {{
                    $getVisibleNames(
                      "manual.actividad_presencial",
                      true,
                      "Actividades Presenciales"
                    )
                  }}
                  <!-- Actividades Presenciales -->
                </b-link>
              </li>
              <li
                v-if="
                  [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
                  this.user.is_superuser
                "
              >
                <b-link :to="{ name: 'AutonomousTeachingActivityView' }">
                  <b-icon-book class="mr-2"></b-icon-book
                  >{{
                    $getVisibleNames(
                      "manual.actividad_autonoma",
                      true,
                      "Actividades Autónomas"
                    )
                  }}
                  <!-- Actividades Autónomas -->
                </b-link>
              </li>
              <li
                v-if="
                  [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
                  this.user.is_superuser
                "
              >
                <b-link :to="{ name: 'ProcedureEvidenceView' }">
                  <b-icon-book class="mr-1"></b-icon-book>
                  {{
                    $getVisibleNames(
                      "manual.evidencia_de_desempeño",
                      true,
                      "Evidencias de Desempeño"
                    )
                  }}
                  <!-- Evidencias de Desempeño -->
                </b-link>
              </li>
              <li
                v-if="
                  [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
                  this.user.is_superuser
                "
              >
                <b-link :to="{ name: 'TaxonomyView' }">
                  <b-icon-book class="mr-2"></b-icon-book
                  >{{ $getVisibleNames("mesh.taxonomy", true, "Taxonomías") }}
                  <!-- Taxonomías -->
                </b-link>
              </li>
              <li
                v-if="
                  [1].includes(this.user.groups[0]) || this.user.is_superuser
                "
              >
                <b-link :to="{ name: 'AlternativeNamesView' }">
                  <b-icon-book class="mr-1"></b-icon-book>
                  Nombres Alternativos
                </b-link>
              </li>
            </ul>
            <ul class="list-unstyled components" v-if="instructives.length > 0">
              <li class="p-0 m-0">
                <p
                  v-if="
                    [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
                    this.user.is_superuser
                  "
                >
                  Documentos
                </p>
              </li>
              <li
                v-if="
                  [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
                  this.user.is_superuser
                "
              >
                <template v-if="files && instructives">
                  <template v-for="item in instructives">
                    <div :key="item.id">
                      <b-link
                        :href="
                          files.find((x) => x.id == item.file)
                            ? files.find((x) => x.id == item.file).file
                            : ''
                        "
                        target="_blank"
                        class="text-break"
                        v-b-tooltip.v-secondary.noninteractive.hover="
                          `${item.name}`
                        "
                      >
                        <b-icon-book class="mr-2"></b-icon-book>{{ item.name }}
                      </b-link>
                    </div>
                  </template>
                </template>
              </li>
            </ul>
            <!-- <ul class="list-unstyled CTAs">
          <li>
            <a
              href="https://cideu.uautonoma.cl/"
              target="_blank"
              class="article"
              >CIDEU</a
            >
          </li>
          <li>
            <a
              href="http://www.pronosworld.com/pronosworld/home.html"
              target="_blank"
              class="article"
              >Pronos</a
            >
          </li>
        </ul> -->
          </template>
        </nav>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters, mapState } from "vuex";
// import Vue from "vue";
// import VueClosable from "vue-closable";
// Vue.use(VueClosable);
// const $ = require("jquery");
// window.$ = $;

export default {
  name: "Toolbar",
  data() {
    return {
      next_evaluations: [],
      // matters: [],
      collapse_sidebar: false,
    };
  },
  computed: {
    ...mapGetters({
      sections: names.SECTIONS,
      evaluations: names.EVALUATIONS,
      careers: names.CAREERS,
      instructives: names.INSTRUCTIVES,
      files: names.FILES,
      isLoged: "isLoged",
      institution: "getInstitution",
    }),
    ...mapState({
      is_loged: "IS_LOGED",
      user: "user",
    }),
    studentSections() {
      let list = [];
      if (!this.user) return list;
      this.user.sections_as_student.forEach((element) => {
        const section = this.sections.find((x) => x.id == element);
        if (section) list.push(section);
      });
      return list;
    },
  },
  methods: {
    getNameCareer(ids_career) {
      if (!ids_career) return "";
      let career = this.careers.find((x) => x.id == ids_career);
      return career ? career.name : "";
    },
    sidebarOpen() {
      this.collapse_sidebar = !this.collapse_sidebar;
    },
    sidebarClose() {
      this.collapse_sidebar = false;
    },
    // handleClickOutside(event) {
    //   if (!this.$refs.menuContainer.contains(event.target)) {
    //     this.menuExpand(); // Cierra el menú logueado
    //   }
    // },
    // addClickOutsideListener() {
    //   document.addEventListener("click", this.handleClickOutside);
    // },
    // removeClickOutsideListener() {
    //   document.removeEventListener("click", this.handleClickOutside);
    // },
    // fetchMatters() {
    //   if (!this.user) return;
    //   this.$store.dispatch(names.FETCH_ALTERNATIVE_NAMES);
    //   this.$restful.Post("/teaching/get_matters/").then((response) => {
    //     this.matters = response;
    //     this.matters.forEach((matter) => {
    //       if (!this.careers.find((career) => career.id == matter.career)) {
    //         this.$store.dispatch(names.FETCH_CAREER, matter.career);
    //       }
    //     });
    //   });
    // },
    fetchNextEvaluations() {
      if (!this.user) return;
      this.$restful.Post("/teaching/get_next_evaluations/").then((response) => {
        this.next_evaluations = response.sort(function (a, b) {
          if (a.start_date > b.start_date) return 1;
          if (a.start_date < b.start_date) return -1;
          return 0;
        });
      });
    },
    // onClose() {
    //   this.collapse_sidebar = false;
    // },
  },
  watch: {
    // user: {
    //   // TODO: Revisar.
    //   handler() {
    //     if (this.timeout) clearTimeout(this.timeout);
    //     this.timeout = setTimeout(() => {
    //       this.fetchMatters();
    //     }, 1000);
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    if (this.is_loged) {
      this.$store.dispatch(names.FETCH_INSTRUCTIVES).then((response) => {
        response.forEach((instructive) => {
          this.$store.dispatch(names.FETCH_FILE, instructive.file);
        });
      });
    }
    // $("#sidebarCollapse").on("click", function () {
    //   this.collapse_sidebar = !this.collapse_sidebar;
    //   $(".sidebar, .section1, .footer, .sidebar-btn").toggleClass("active");
    //   $(".collapse.in").toggleClass("in");
    //   $("a[aria-expanded=true]").attr("aria-expanded", "false");
    // });
    // $(document).click(function () {
    //   this.collapse_sidebar = !this.collapse_sidebar;
    //   $(".sidebar, .section1, .footer, .sidebar-btn").removeClass("active");
    // });
    // $("#sidebarCollapse").click(function (e) {
    //   e.stopPropagation();
    //   return false;
    // });
    // $("ul li").on("click", function () {
    //   $("ul li").removeClass("active");
    //   $(this).addClass("active");
    // });
  },
  beforeDestroy() {
    // this.removeClickOutsideListener();
  },
};
</script>

<style scoped>
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.wrapper {
  display: flex;
  width: 100%;
}
.b-sidebar-left {
  background-color: var(--kl-menu-color) !important;
}
.sidebar {
  /* margin-top: 4%;
  margin-left: 250px; */
  /* padding-bottom: 0.8rem; */
  /* padding-top: 2.5rem; */
  /* width: 250px; */
  /* position: fixed;
  top: -5%;
  left: 0; */
  /* margin-left: -5px; */
  padding-bottom: 0.8rem;
  height: 100%;
  z-index: 1001;
  background: var(--kl-menu-color);
  color: var(--secondary-font-color);
  transition: all 0.3s;
  font-weight: bold;
}
ul {
  margin-left: -10px;
}
li {
  padding-left: 15px;
}
.b-sidebar.b-sidebar-left {
  background-color: var(--kl-menu-color) !important;
}

.sidebar.active,
.sidebar-btn-active {
  margin-left: 0px;
}

.sidebar .sidebar-header {
  padding-top: 11%;
  padding-bottom: 8px;
  background: var(--kl-menu-color) !important;
  /* box-shadow: 0 1.8px 1px -2px rgb(0, 0, 0); */
}

.sidebar ul.components {
  padding: 10px 0;
  text-align: left;
  font-size: 13px;
  border-bottom: 1px solid var(--kl-menu-hover-color);
}

.sidebar ul p {
  color: var(--secondary-font-color);
  text-align: center;
  padding: 8px;
  background: var(--kl-menu-hover-color);
  /* box-shadow: 0 0 7px -4px rgb(0, 0, 0); */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sidebar ul li a {
  padding: 3px;
  font-size: 10pt;
  display: block;
}

.sidebar ul li a:hover {
  color: var(--secondary-font-color);
  background: var(--kl-menu-hover-color);
  /* box-shadow: 0 2px 4px -2px rgb(0, 0, 0); */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: var(--secondary-font-color);
  /* background: #000000; */
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: var(--header-color);
}

ul.CTAs {
  padding: 10px;
}

ul.CTAs a {
  text-align: center;
  font-size: 10pt !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

/* a.article {
  background: var(--kl-menu-hover-color) !important;
  color: var(--secondary-font-color) !important;
  box-shadow: 0 1.8px 7px -2px rgb(0, 0, 0);
}

a.article:hover {
  color: var(--secondary-font-color) !important;
  background: var(--kl-menu-color) !important;
} */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}
.container-btn-inside-sidebar {
  text-align: right;
  min-height: 28px;
}
.btn-collapse2 {
  width: 25px;
  height: 25px;
  margin-top: 4px;
  margin-right: 1px;
  background: #50a1ba !important;
  border: var(--kl-menu-hover-color) !important;
  color: #fff;
}
.sidebar-btn {
  position: fixed;
  top: 61.5px !important;
  left: 0;
  z-index: 1050 !important;
  transition: all 0.3s;
  margin-left: 0px;
}
.sidebar-btn.active {
  margin-left: 250px;
}
.sidebar-btn >>> .btn-collapse {
  width: 25px;
  height: 25px;
  margin-top: 4px;
  background: #50a1ba !important;
  border: var(--kl-menu-hover-color) !important;
  color: #fff;
}
.sidebar-btn .btn-collapse:hover {
  background: #62abc1 !important;
}
.sidebar-btn >>> .btn-collapse:focus {
  outline: none !important;
}
.sidebar-btn-icon {
  transform: translate(-28%, -10%);
}
.hidden-sidebar-btn {
  display: none;
}
.text-break {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/***** Media Querys *****/

@media only screen and (min-width: 601px) and (max-width: 1000px) {
  .sidebar ul p,
  .sidebar ul li a {
    font-size: 13px;
  }

  .sidebar .sidebar-header {
    padding-top: 27%;
    font-size: 10px;
  }
  .sidebar-btn {
    top: 61.5px !important;
    left: 0;
  }
}

@media only screen and (max-width: 600px) {
  .sidebar ul p,
  .sidebar ul li a {
    font-size: 13px;
  }

  .sidebar .sidebar-header {
    font-size: 10px;
  }
  .sidebar-btn >>> .btn-collapse {
    margin-top: 1px;
  }
  .sidebar-btn {
    position: fixed;
    top: 61px !important;
    left: 0;
    margin-top: 1px;
    z-index: 30000 !important;
    transition: all 0.3s;
    margin-left: 0px !important;
  }
  .sidebar {
    /* margin-top: 4%;
    margin-left: -590px;
    padding-bottom: 0.8rem;
    padding-top: 2.5rem;
    position: fixed;
    top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    z-index: 1001;
    background: var(--kl-menu-color);
    color: var(--secondary-font-color);
    transition: all 0.3s;
    font-weight: bold;
    scroll-behavior: auto;
    overflow: scroll;
  }
}

@media only screen and (max-height: 600px) {
  .sidebar {
    overflow: scroll;
    /* padding-bottom: 1em; */
  }
}
</style>