import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import { store } from './store/store';
import public_routes from '@/router/public';

import { BootstrapVue, ModalPlugin, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

// Importar métodos y filtros
import {
    getVisibleNames, filtered, equals, hasPermissions, hasObjectPermission,
    lightOrDark, htmlToPlainText, logOut
} from '@/utils/utils';
import '@/utils/filters';

Vue.use(BootstrapVue);
Vue.use(ModalPlugin);
Vue.use(BootstrapVueIcons);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

let moment = require('moment-timezone');
moment.tz.setDefault('America/Santiago');
require('moment/locale/es')
Vue.use(require('vue-moment'), {
    moment
});

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import ButtonAdd from '@/components/reusable/ButtonAdd';
Vue.component("button-add", ButtonAdd);

import ButtonEdit from '@/components/reusable/ButtonEdit';
Vue.component("button-edit", ButtonEdit);

import ButtonDelete from '@/components/reusable/ButtonDelete';
Vue.component("button-delete", ButtonDelete);

import Footer from '@/components/public/Footer.vue';
Vue.component('footer-component', Footer);

import Toolbar from '@/components/public/Toolbar.vue';
Vue.component('toolbar-component', Toolbar);

import VueGlobalVar from 'vue-global-var';
import restful from '@/utils/restful';

// Esta variable se ocupará para poder probar como se vería en la institución Duoc y hacer pruebas locales.
export const debug_change_duoc = false;
// Esta variable se ocupará para poder probar como se vería en la institución Unab y hacer pruebas locales.
export const debug_change_unab = false;
// Esta variable se ocupará para poder probar como se vería en la institución MANUELA BELTRÁN y hacer pruebas locales.
export const debug_change_umb = false;
// Variable para controlar si se quiere colocar en mantenimiento las instituciones…
export const maintenance_view = false;
// Variable para controlar el tamaño máximo permitido para los archivos y el peso que se mostrará en sus respectivos mensajes.
export const max_size_in_files = {
    max_size: 12582912,
    max_size_text: "6MB",
}

Vue.use(VueGlobalVar, {
    globals: {
        $restful: restful,
        $getVisibleNames: getVisibleNames,
        $filtered: filtered,
        $equals: equals,
        $hasPermissions: hasPermissions,
        $hasObjectPermission: hasObjectPermission,
        $lightOrDark: lightOrDark,
        $htmlToPlainText: htmlToPlainText,
        $debug_change_duoc: debug_change_duoc,
        $debug_change_unab: debug_change_unab,
        $debug_change_umb: debug_change_umb,
        $maintenance_view: maintenance_view,
        $max_size_in_files: max_size_in_files,
        $logOut: logOut,
    }
});

Vue.config.productionTip = false;


router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters.isLoged;
    if (from.name == 'DashboardView' && isAuthenticated && store.getters.getUser && !store.getters.getUser.has_changed_password) {
        return
    }
    else if (from.name == 'DashboardView' && isAuthenticated && store.getters.getInstitution && store.getters.getInstitution.id != 16 && maintenance_view)
        return
    if (!public_routes.map(x => x.name).includes(to.name) && !isAuthenticated) {
        next({ name: 'Login', query: { redirect: to.fullPath } });
    }
    // if (to.name == 'Login' && isAuthenticated) {
    //     router.replace({ name: 'DashboardView' });
    //     // next();
    //     return
    // }
    next();
    // router.replace('/login', () => {
    //   next(from);
    // })
    // }
});

Vue.directive('can', {
    bind: function (el, binding, vnode) {
        try {
            const expressions = binding.expression.split(' ');
            const permissions = JSON.parse(localStorage.getItem('permissions'));
            expressions.forEach(expression => {
                if (!permissions.includes(expression.replace(/'/g, "").replace(/"/g, ""))) {
                    vnode.elm.style.display = "none";
                    return;
                }
            });
        } catch (error) {
            console.log('Directive error: ', error);
            vnode.elm.style.display = "none";
        }
    }
});

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    mounted: function () {
        const logo_kl = require("@/assets/logo_klearning.png");
        Vue.prototype.$logo = logo_kl;
    }
});
