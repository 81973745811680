
import Vue from "vue";
import { store } from '@/store/store';
import * as names from "@/store/names";
import router from '@/router/index';
import public_routes from '@/router/public';

export const EventBus = new Vue({
  data: {
    processes: 0,
    ended: 0,
  },
  methods: {
    isAuthenticated() {
      return this.$msal.isAuthenticated();
    },
    signIn() {
      console.log("signIn");
      if (!this.$msal.isAuthenticated()) {
        this.$msal.signIn();
      }
    },
    signOut() {
      console.log("signOut");
      if (this.$msal.isAuthenticated()) {
        this.$msal.signOut();
      }
    },
    startLoad() {
      if (this.processes == 0) this.$emit("loadStarted");
      this.processes += 1;
      this.$emit("increment", this.processes);
    },
    endLoad() {
      this.ended += 1;
      this.$emit("decrement", this.ended);
      if (this.processes <= this.ended) {
        this.processes = 0;
        this.ended = 0;
      }
      if (this.processes == 0) this.$emit("loadFinished");
    },
  },
});

export function isValidJwt() {
  const jwt = localStorage.jwt_token;
  if (!jwt || jwt.split(".").length < 3) {
    return false;
  }
  const data = JSON.parse(atob(jwt.split(".")[1]));
  const exp = new Date(data.exp * 1000);
  const now = new Date();
  return now < exp;
}

export function expirationTime(jwt) {
  if (!jwt || jwt.split(".").length < 3) {
    return 0;
  }
  const data = JSON.parse(atob(jwt.split(".")[1]));
  const exp = new Date(data.exp * 1000);
  const now = new Date();
  return Math.abs(now - exp);
}

export function expiresAt(jwt) {
  if (!jwt || jwt.split(".").length < 3) {
    return 0;
  }
  const data = JSON.parse(atob(jwt.split(".")[1]));
  const exp = new Date(data.exp * 1000);
  return exp;
}

export function generateUniqueId() {
  return (
    Math.random()
      .toString(36)
      .substring(2) + Date.now().toString(36)
  );
}

export function areEqualsObjects(object1, object2) {
  Object.entries(object1).forEach((key, value) => {
    if (Object.prototype.hasOwnProperty.call(object2, key)) return false;
    if (object2[key] != value) return false;
  });
  return JSON.stringify(object1) == JSON.stringify(object2);
}

export function toast(message) {
  Vue.swal
    .mixin({
      toast: true,
      position: "bottom-start",
      showConfirmButton: false,
      timer: 3000,
      title: message,
    })
    .fire();
}

export function customToast(message, position, timer) {
  Vue.swal
    .mixin({
      toast: true,
      position: position,
      showConfirmButton: false,
      timer: timer,
      title: message,
    })
    .fire();
}

/**
 * Muestra una alerta de carga con un mensaje personalizado.
 * 
 * @param {string} [title] - El título de la alerta (por defecto: "Subiendo archivo...").
 * @param {string} [text] - El texto descriptivo de la alerta (por defecto: "Por favor, espera mientras se sube el archivo.").
 * @returns {void}
 * Función necesaria para finalizar la alerta 
 * @function  this.$swal.close()
*/
export function showLoadingAlert(title = "Subiendo archivo...", text = "Por favor, espera mientras se sube el archivo.") {
  Vue.swal
    .fire({
      title,
      text,
      allowOutsideClick: false,
      allowEscapeKey: false,
      onBeforeOpen: () => {
        Vue.swal.showLoading();
      },
    });
}


export function round(number) {
  return +(Math.round(Number(number) + "e+2") + "e-2");
}

export function base64UrlToBytes(base64Url) {
  const padding = '='.repeat((4 - base64Url.length % 4) % 4);
  const base64 = (base64Url + padding).replace(/-/g, '+').replace(/_/g, '/');
  const bytes = window.atob(base64).split('').map(char => char.charCodeAt(0));
  return new Uint8Array(bytes);
}

export const backend_usecured_routes = [
  '/token/',
  '/token/refresh/',
  '/login/',
  '/authentication/send_reset_code/',
  '/authentication/reset-password/',
  '/common/contact-data/',
  '/health_check',
];

/**
 * Construye una cadena de parámetros URL a partir de un objeto de payload.
 * 
 * @param {Object} payload - El objeto que contiene los parámetros a convertir en una cadena de URL.
 * @param {Array|string|number|boolean} payload[key] - Cada clave puede tener un valor que sea un array, string, número o booleano.
 * 
 * @returns {string|null} - La cadena de parámetros URL construida, o `null` si no se agregaron parámetros más allá de `all_data=True`.
 */
export const buildUrlParams = (payload) => {
  let params = ['all_data=True'];
  Object.keys(payload).forEach(key => {
    if (Array.isArray(payload[key])) {
      if (payload[key].length > 0) {
        params.push(`${key}=${payload[key].join(",")}`);
      }
    } else if (payload[key]) {
      params.push(`${key}=${payload[key]}`);
    }
  });
  return params.length === 1 ? null : params.join('&');
};

/**
 * Filtra si en la cadena A se incluye algo de la cadena B.
 * Este método normaliza las cadenas para eliminar tildes y caracteres especiales.
 * @param {string} A - La primera cadena a comparar.
 * @param {string} B - La cadena que se desea buscar dentro de A.
 * @returns {boolean} - Devuelve true si A contiene B, false en caso contrario.
 */
export const filtered = (A, B) => {
  if (String(A).normalize("NFD")
    .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
    .normalize()
    .trim()
    .toLowerCase()
    .includes(String(B).normalize("NFD")
      .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
      .normalize()
      .trim()
      .toLowerCase())) return true
  else return false
};

/**
 * Compara si la cadena A es igual a la cadena B.
 * Este método normaliza las cadenas para eliminar tildes y caracteres especiales.
 * @param {string} A - La primera cadena a comparar.
 * @param {string} B - La segunda cadena a comparar.
 * @returns {boolean} - Devuelve true si A es igual a B, false en caso contrario.
 */
export const equals = (A, B) => {
  if (String(A).normalize("NFD")
    .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
    .normalize()
    .trim()
    .toLowerCase() ==
    String(B).normalize("NFD")
      .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
      .normalize()
      .trim()
      .toLowerCase()) return true
  else return false
};

/**
 * Obtiene el nombre visible de una tabla basada en un nombre alternativo y la escuela del usuario.
 * @param {string} table_name - El nombre de la tabla a verificar.
 * @param {boolean} isPlural - Indica si el nombre es plural.
 * @param {string} original_name - El nombre original de la tabla.
 * @returns {string} - El nombre visible basado en la configuración de la escuela del usuario, o el nombre original.
 */
export const getVisibleNames = (table_name, isPlural, original_name) => {
  if (!store.state.user) return original_name;
  if (!store.state.user.school) return original_name;
  const alternative_name = store.state.alternative_names.alternative_names.find(
    (x) => x.table_name == table_name && x.school == store.state.user.school
  );
  if (!alternative_name) return original_name;
  if (isPlural)
    return alternative_name ?
      alternative_name.visible_name_plural ?
        alternative_name.visible_name_plural :
        alternative_name.verbose_name_plural :
      original_name;
  else
    return alternative_name ?
      alternative_name.visible_name ?
        alternative_name.visible_name :
        alternative_name.verbose_name :
      original_name;
};

/**
 * Verifica si el usuario tiene permisos sobre un objeto basado en el nombre de la tabla, método y, opcionalmente, un ID.
 * @param {string} table_name - El nombre de la tabla a verificar.
 * @param {string} methods - El tipo de operación a verificar ('add', 'change', 'delete', 'special').
 * @param {number|null} [id=null] - El ID del objeto a verificar (opcional).
 * @returns {boolean} - Devuelve true si el usuario tiene los permisos necesarios, false en caso contrario.
 */
export const hasObjectPermission = (table_name, methods, id = null) => {
  const table = store.state.positions.user_table_access.find(x => equals(x.table_name, table_name))
  if (table) {
    if (equals(methods, 'add') && table.can_create) return true
    if (equals(methods, 'change') && (equals(table.can_update, 'all') || table.can_update.split(",").map(Number).includes(id))) return true
    if (equals(methods, 'delete') && (equals(table.can_delete, 'all') || table.can_delete.split(",").map(Number).includes(id))) return true
    if (equals(methods, 'special') && (equals(table.can_do_special_behavior, 'all') || table.can_do_special_behavior.split(",").map(Number).includes(id))) return true
  } else return false
  return false
}

/**
 * Verifica si el usuario tiene permisos específicos.
 * @param {string|null} [permission=null] - El permiso a verificar (opcional).
 * @returns {boolean} - Devuelve true si el usuario tiene los permisos, false en caso contrario.
 */
export const hasPermissions = (permission = null) => {
  if (!store.getters.isLoged && router.currentRoute.name != "Welcome") {
    store.commit(names.MUTATE_VISIBILITY_CRUD, false);
    router.push({ name: "Welcome" });
    return false
  }
  else {
    if (!permission) {
      store.commit(names.MUTATE_VISIBILITY_CRUD, false);
      return false
    } else {
      try {
        const permissions = JSON.parse(localStorage.getItem('permissions'));
        const expressions = permission.split(' ');
        const allows_crud = store.state.utils.allows_crud;
        const findExpession = expressions.find(expression => permissions.includes(expression.replace(/'/g, "").replace(/"/g, "")));
        if (findExpession) store.commit(names.MUTATE_VISIBILITY_CRUD, true);
        else store.commit(names.MUTATE_VISIBILITY_CRUD, false);
        if (allows_crud && findExpession) {
          return allows_crud;
        }
        else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
  }
}

/**
 * Determina si un color es claro u oscuro basado en su valor RGB o HEX.
 * @param {string} color - El color en formato HEX o RGB.
 * @returns {boolean} - Devuelve true si el color es claro, false si es oscuro.
 */
export const lightOrDark = (color) => {
  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +(
      "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
    );

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return true; // return true if the colour is brighter
  } else {
    return false; // return false if the colour is darker
  }
}

/**
 * Convierte una cadena de HTML en texto plano.
 * @param {string} html - La cadena de HTML a convertir.
 * @returns {string} - El texto plano resultante del HTML.
 */
export const htmlToPlainText = (html) => {
  // Crea un nuevo DOMParser
  const parser = new DOMParser();
  // Parsea el HTML a un nuevo documento
  const doc = parser.parseFromString(html, 'text/html');
  // Retorna el texto del documento, que será el texto plano
  return doc.body.textContent || "";
}

export const logOut = () => {
  if (!public_routes.map(x => x.name).includes(router.currentRoute.name) || router.currentRoute.name == 'Page404') {
    router.push({ name: "Welcome" });
  }
  store.commit('clearLoginData');
}