var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"menuContainer",attrs:{"id":"toolbar-component"}},[_c('div',{staticClass:"sidebar-btn noprint",class:{ 'hidden-sidebar-btn': !_vm.isLoged },attrs:{"id":"sidebar-btn"}},[(!_vm.collapse_sidebar && _vm.user && !_vm.user.groups.includes(9))?_c('b-button',{ref:"sidebarCollapse",staticClass:"btn-collapse",attrs:{"id":"sidebarCollapse","variant":"none","size":"sm"},on:{"click":_vm.sidebarOpen}},[_c('b-icon',{staticClass:"sidebar-btn-icon",attrs:{"icon":"caret-right-fill","scale":"1.2"}})],1):_vm._e()],1),_c('b-sidebar',{staticClass:"b-sidebar-left",attrs:{"id":"sidebar-left","text-variant":"light","left":"","shadow":"","z-index":"1051","width":"250px","no-header":"","backdrop":true,"backdrop-variant":"none"},model:{value:(_vm.collapse_sidebar),callback:function ($$v) {_vm.collapse_sidebar=$$v},expression:"collapse_sidebar"}},[(_vm.user)?_c('nav',{staticClass:"sidebar",attrs:{"id":"sidebar"}},[(
            [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
            this.user.is_superuser
          )?_c('div',{staticClass:"sidebar-header"},[_c('h3',{staticClass:"mb-0 pb-0"},[_vm._v("Menú")]),_c('div',{staticClass:"container-btn-inside-sidebar"},[(_vm.collapse_sidebar)?_c('b-button',{ref:"sidebarCollapse",staticClass:"btn-collapse2",attrs:{"id":"sidebarCollapse","variant":"none","size":"sm"},on:{"click":_vm.sidebarOpen}},[_c('b-icon',{staticClass:"sidebar-btn-icon",attrs:{"icon":"caret-right-fill","rotate":"180","scale":"1.2"}})],1):_vm._e()],1)]):_vm._e(),[([1].includes(this.user.groups[0]) || this.user.is_superuser)?_c('ul',{staticClass:"list-unstyled components"},[_c('p',[_vm._v("Gestión Administrativa")]),(this.user.is_superuser)?_c('li',[_c('b-link',{attrs:{"to":{ name: 'AdminUsersView' }}},[_c('b-icon-book',{staticClass:"mr-2"}),_vm._v("Administrar Usuarios ")],1)],1):_vm._e(),(
                _vm.institution &&
                _vm.institution.kl_assessment &&
                ([1].includes(this.user.groups[0]) || this.user.is_superuser)
              )?_c('li',[_c('b-link',{attrs:{"to":{ name: 'SectionView' }}},[_c('b-icon-book',{staticClass:"mr-2"}),_vm._v(_vm._s(_vm.$getVisibleNames( "manual.administrar_seccion", true, "Administrar Secciones" ))+" ")],1)],1):_vm._e(),(
                _vm.institution &&
                _vm.institution.kl_assessment &&
                ([1].includes(this.user.groups[0]) || this.user.is_superuser)
              )?_c('li',[_c('b-link',{attrs:{"to":{ name: 'ManageEvaluationsView' }}},[_c('b-icon-book',{staticClass:"mr-2"}),_vm._v("Administrar Evaluaciones ")],1)],1):_vm._e()]):_vm._e(),(
              [1, 2, 3, 4, 5, 6].includes(this.user.groups[0]) ||
              this.user.is_superuser
            )?_c('ul',{staticClass:"list-unstyled components"},[_c('p',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.gestion_curricular_y_pedagogica", false, "Gestión Curricular y Pedagógica" ))+" ")]),(
                [1, 2, 3, 4, 5, 6].includes(this.user.groups[0]) ||
                this.user.is_superuser
              )?_c('li',[_c('b-link',{attrs:{"to":{ name: 'ActsView' }}},[_c('b-icon-book',{staticClass:"mr-2"}),_vm._v(_vm._s(_vm.$getVisibleNames( "manual.acta_de_comite", true, "Actas de Comités" ))+" ")],1)],1):_vm._e(),(
                ([1, 2, 3, 4, 5].includes(this.user.groups[0]) ||
                  this.user.is_superuser) &&
                _vm.institution &&
                _vm.institution.kl_assessment &&
                (_vm.institution.internal_use_id == 'ciisa_uss' ||
                  _vm.institution.internal_use_id == 'duoc_uc' ||
                  _vm.institution.show_kl_reports)
              )?_c('li',[_c('b-link',{attrs:{"to":{ name: 'GeneralReport' }}},[_c('b-icon-book',{staticClass:"mr-2"}),_vm._v(_vm._s(_vm.$getVisibleNames("manual.reporte", true, "Reportes"))+" ")],1)],1):_vm._e()]):_vm._e(),(
              [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
              this.user.is_superuser
            )?_c('ul',{staticClass:"list-unstyled components"},[_c('p',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.recurso_metodologico", true, "Recursos Metodológicos" ))+" ")]),(
                [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
                this.user.is_superuser
              )?_c('li',[_c('b-link',{attrs:{"to":{ name: 'MethodologicalStrategyView' }}},[_c('b-icon-book',{staticClass:"mr-2"}),_vm._v(_vm._s(_vm.$getVisibleNames( "mesh.methodologicalstrategy", true, "Estrategias Metodológicas" ))+" ")],1)],1):_vm._e(),(
                [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
                this.user.is_superuser
              )?_c('li',[_c('b-link',{attrs:{"to":{ name: 'DirectTeachingActivityView' }}},[_c('b-icon-book',{staticClass:"mr-1"}),_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.actividad_presencial", true, "Actividades Presenciales" ))+" ")],1)],1):_vm._e(),(
                [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
                this.user.is_superuser
              )?_c('li',[_c('b-link',{attrs:{"to":{ name: 'AutonomousTeachingActivityView' }}},[_c('b-icon-book',{staticClass:"mr-2"}),_vm._v(_vm._s(_vm.$getVisibleNames( "manual.actividad_autonoma", true, "Actividades Autónomas" ))+" ")],1)],1):_vm._e(),(
                [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
                this.user.is_superuser
              )?_c('li',[_c('b-link',{attrs:{"to":{ name: 'ProcedureEvidenceView' }}},[_c('b-icon-book',{staticClass:"mr-1"}),_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.evidencia_de_desempeño", true, "Evidencias de Desempeño" ))+" ")],1)],1):_vm._e(),(
                [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
                this.user.is_superuser
              )?_c('li',[_c('b-link',{attrs:{"to":{ name: 'TaxonomyView' }}},[_c('b-icon-book',{staticClass:"mr-2"}),_vm._v(_vm._s(_vm.$getVisibleNames("mesh.taxonomy", true, "Taxonomías"))+" ")],1)],1):_vm._e(),(
                [1].includes(this.user.groups[0]) || this.user.is_superuser
              )?_c('li',[_c('b-link',{attrs:{"to":{ name: 'AlternativeNamesView' }}},[_c('b-icon-book',{staticClass:"mr-1"}),_vm._v(" Nombres Alternativos ")],1)],1):_vm._e()]):_vm._e(),(_vm.instructives.length > 0)?_c('ul',{staticClass:"list-unstyled components"},[_c('li',{staticClass:"p-0 m-0"},[(
                  [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
                  this.user.is_superuser
                )?_c('p',[_vm._v(" Documentos ")]):_vm._e()]),(
                [1, 2, 3, 4, 5, 6, 7].includes(this.user.groups[0]) ||
                this.user.is_superuser
              )?_c('li',[(_vm.files && _vm.instructives)?[_vm._l((_vm.instructives),function(item){return [_c('div',{key:item.id},[_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.hover",value:(
                        `${item.name}`
                      ),expression:"\n                        `${item.name}`\n                      ",modifiers:{"v-secondary":true,"noninteractive":true,"hover":true}}],staticClass:"text-break",attrs:{"href":_vm.files.find((x) => x.id == item.file)
                          ? _vm.files.find((x) => x.id == item.file).file
                          : '',"target":"_blank"}},[_c('b-icon-book',{staticClass:"mr-2"}),_vm._v(_vm._s(item.name)+" ")],1)],1)]})]:_vm._e()],2):_vm._e()]):_vm._e()]],2):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }