<template>
  <div>
    <b-navbar id="nav-bar" type="dark" fixed="top" ref="navbarCollapse">
      <div
        class="mr-0 brand-section"
        v-b-tooltip.v-secondary.noninteractive.hover="'Ir al Inicio'"
        @click="closeMenuNoLoged2"
      >
        <router-link :to="{ name: isLoged ? 'DashboardView' : 'Welcome' }">
          <b-img
            v-if="
              !isLoged || institution_logo == null || institution_logo == 'null'
            "
            v-bind:src="logo"
            :alt="app_name"
            class="logo pt-1 pb-1 mr-auto mt-1"
            :title="institution ? institution.name : ''"
            style="cursor: pointer"
          ></b-img>
          <b-img
            v-if="
              isLoged &&
              institution_logo &&
              institution_logo != null &&
              institution_logo != 'null'
            "
            thumbnail
            v-bind:src="institution_logo"
            :alt="institution ? institution.name : ''"
            class="logo mr-auto"
            :title="institution ? institution.name : ''"
          ></b-img>
          <b-navbar-brand v-if="!isLoged" class="brand-text mr-auto">
          </b-navbar-brand>
          <b-navbar-brand v-else class="brand-text mr-auto">
            {{ institution ? institution.name : "" }}
          </b-navbar-brand>
        </router-link>
      </div>
      <div
        class="user-info-container"
        v-bind:class="{ 'right-setting': !isLoged }"
      >
        <b-navbar-nav>
          <b-nav
            class="landing-nav"
            v-bind:class="{ 'not-visible': isLoged || app_name == 'GERA' }"
          >
            <div class="nav-items">
              <b-nav-item style="margin-left: 1%" :to="{ name: 'Welcome' }">
                <span :class="{ 'current-view': $route.name == 'Welcome' }">
                  Home
                </span>
              </b-nav-item>
              <b-nav-item style="margin-left: 1%" :to="{ name: 'AboutUsView' }">
                <span :class="{ 'current-view': $route.name == 'AboutUsView' }">
                  Quiénes Somos
                </span>
              </b-nav-item>
              <b-nav-item
                style="margin-left: 1%"
                :to="{ name: 'SolutionsView' }"
              >
                <span
                  :class="{ 'current-view': $route.name == 'SolutionsView' }"
                >
                  Soluciones
                </span>
              </b-nav-item>
              <b-nav-item
                style="margin-left: 1%"
                :to="{ name: 'TrainingView' }"
              >
                <span
                  :class="{ 'current-view': $route.name == 'TrainingView' }"
                >
                  Capacitación
                </span>
              </b-nav-item>
              <!-- <b-nav-item style="margin-left: 1%">
                <span :class="{ 'current-view': $route.name == 'Contact' }">
                  Contacto
                </span>
              </b-nav-item> -->
            </div>
          </b-nav>
          <HelpComponent class="help-icon" v-if="isLoged"></HelpComponent>
          <!-- Perfil de usuario -->
          <b-nav>
            <b-nav-item-dropdown
              v-if="isLoged"
              disabled
              :class="{
                'dropdown-adjustment': isLoged,
                shown: isLoged,
                'sign-in': !isLoged,
              }"
            >
              <template #button-content>
                <em class="user-name">{{ userName }}</em>
                <b-icon class="user-icon" icon="person-circle"></b-icon>
              </template>
              <b-dropdown-item :to="{ name: 'StudentCardView' }"
                >Perfil</b-dropdown-item
              >
              <b-dropdown-item @click="$logOut">Salir</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-button
              v-else
              variant="none"
              :to="{ name: 'Login' }"
              class="user-button p-0"
            >
              <div class="user-button-left mr-2 pl-3">ENTRAR</div>
              <div class="user-button-right" @click="closeMenuNoLoged2">
                <b-icon
                  class="user-icon2"
                  scale="1.8"
                  icon="person-circle"
                ></b-icon>
              </div>
            </b-button>
          </b-nav>
        </b-navbar-nav>
      </div>
      <div
        class="mobile-nav"
        :class="{ 'not-visible': isLoged || app_name == 'KL' }"
        @click="closeMenuNoLoged"
        @click.stop
        v-b-toggle.navbar-toggle-collapse
      >
        <b-button ref="collapseButton" class="btn-collapse-mobile">
          <b-icon v-if="expanded" icon="caret-up-fill"></b-icon>
          <b-icon v-else icon="caret-down-fill"></b-icon>
        </b-button>
      </div>
    </b-navbar>
    <div :class="{ 'not-logged-in': !isLoged }" class="loadingBar noprint">
      <b-progress
        :value="loading_value"
        v-if="loading"
        height="7px"
        :max="loading_max"
        animated
      ></b-progress>
    </div>
    <b-collapse
      v-if="expanded"
      :class="{ 'not-visible': isLoged || app_name == 'KL' }"
      id="navbar-toggle-collapse"
      class="mobile-nav"
    >
      <div class="nav-btn">
        <b-button
          :to="{ name: 'Welcome' }"
          class="navigation-link m-1"
          variant="info"
          @click="closeMenuNoLoged"
        >
          Home
        </b-button>
        <b-button
          :to="{ name: 'SolutionsView' }"
          class="navigation-link m-1"
          variant="info"
          @click="closeMenuNoLoged"
        >
          Soluciones
        </b-button>
        <b-button
          :to="{ name: 'AboutUsView' }"
          class="navigation-link m-1"
          variant="info"
          @click="closeMenuNoLoged"
        >
          Quiénes Somos
        </b-button>
        <b-button
          :to="{ name: 'TrainingView' }"
          class="navigation-link m-1"
          variant="info"
          @click="closeMenuNoLoged"
        >
          Capacitación
        </b-button>
      </div>
    </b-collapse>
    <div
      v-if="isLoged"
      class="b-nav-section noprint"
      :class="{ 'nav-expanded': expanded }"
    >
      <b-nav class="resources-b-nav">
        <b-nav-item-dropdown
          size="sm"
          class="resources-drop first-drop m-0"
          offset="4"
          text="Definiciones Organizacionales"
          disabled
        >
          <b-dropdown-item
            v-if="user && ([1].includes(user.groups[0]) || user.is_superuser)"
            :to="{ name: 'RegionView' }"
          >
            <b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("common.region", true, "Región")
            }}</b-dropdown-item
          >
          <b-dropdown-item
            v-if="user && ([1].includes(user.groups[0]) || user.is_superuser)"
            :to="{ name: 'CommuneView' }"
          >
            <b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("common.commune", true, "Comuna")
            }}</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'CampusListView' }">
            <b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("mesh.campus", true, "Sedes")
            }}</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'FacultyListView' }">
            <b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("mesh.faculty", true, "Facultades")
            }}</b-dropdown-item
          >
          <b-dropdown-item
            v-if="
              user && (![6, 7].includes(user.groups[0]) || user.is_superuser)
            "
            :to="{ name: 'PeriodView' }"
          >
            <b-icon icon="chevron-right" scale="0.8"></b-icon>Períodos
            Académicos</b-dropdown-item
          >
          <b-dropdown-item
            v-if="user && user.is_superuser"
            :to="{ name: 'TimeAllocationView' }"
          >
            <b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "mesh.timeallocation",
                true,
                "Divisiones de tiempo"
              )
            }}</b-dropdown-item
          >
          <b-dropdown-item
            :to="{ name: 'GenericCareerView' }"
            v-if="
              user &&
              (![6, 7].includes(user.groups[0]) || user.is_superuser) &&
              ((institution && institution.internal_use_id == 'duoc_uc') ||
                $debug_change_duoc)
            "
          >
            <b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("mesh.genericcareer", true, "Carrera Genérica")
            }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          size="sm"
          class="resources-drop second-drop m-0"
          text="Definiciones Curriculares"
          disabled
        >
          <b-dropdown-item
            v-if="user && (![7].includes(user.groups[0]) || user.is_superuser)"
            :to="{ name: 'EgressProfileTypeView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "mesh.egressprofiletype",
                true,
                "Tipos de Perfiles de Egreso"
              )
            }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="user && (![7].includes(user.groups[0]) || user.is_superuser)"
            :to="{ name: 'ModalityEgressProfileView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "mesh.modalityegressprofile",
                false,
                "Modalidad del Perfil de Egreso"
              )
            }}</b-dropdown-item
          >
          <b-dropdown-item
            v-if="
              !(
                (institution && institution.internal_use_id == 'duoc_uc') ||
                $debug_change_duoc
              ) &&
              user &&
              (![7].includes(user.groups[0]) || user.is_superuser)
            "
            :to="{ name: 'CompetenceTypeView' }"
          >
            <b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "mesh.competencetype",
                true,
                "Tipos de Competencias"
              )
            }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              !(
                (institution && institution.internal_use_id == 'duoc_uc') ||
                $debug_change_duoc
              ) &&
              user &&
              (![7].includes(user.groups[0]) || user.is_superuser)
            "
            :to="{ name: 'CyclesListView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{ $getVisibleNames("mesh.cycle", true, "Ciclos Formativos") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="user && (![7].includes(user.groups[0]) || user.is_superuser)"
            :to="{ name: 'FormationAreaView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("mesh.formationarea", true, "Áreas De Formación")
            }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="user && (![7].includes(user.groups[0]) || user.is_superuser)"
            :to="{ name: 'FormationLineView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "mesh.formationline",
                true,
                "Líneas de Formación"
              )
            }}</b-dropdown-item
          >
          <b-dropdown-item
            v-if="user && (![7].includes(user.groups[0]) || user.is_superuser)"
            :to="{ name: 'MatterTypeView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("mesh.mattertype", true, "Tipos de Asignatura")
            }}</b-dropdown-item
          >
          <b-dropdown-item
            v-if="user && (![7].includes(user.groups[0]) || user.is_superuser)"
            :to="{ name: 'ModalityView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "mesh.modality",
                false,
                "Modalidad de Asignaturas"
              )
            }}</b-dropdown-item
          >

          <b-dropdown-item
            v-if="user && (![7].includes(user.groups[0]) || user.is_superuser)"
            :to="{ name: 'BaseMattersWrapperView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "mesh.basematterwrapper",
                true,
                "Envoltorio de Asignaturas"
              )
            }}</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'CertificationView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "mesh.certification",
                true,
                "Certificación de Credenciales"
              )
            }}</b-dropdown-item
          >
          <b-dropdown-item
            v-if="user && (![7].includes(user.groups[0]) || user.is_superuser)"
            :to="{ name: 'EvaluationSituationView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "teaching.evaluationsituation",
                true,
                "Situación Evaluativas"
              )
            }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              institution &&
              institution.kl_assessment &&
              user &&
              ([1].includes(user.groups[0]) || user.is_superuser) &&
              ![7].includes(user.groups[0])
            "
            :to="{ name: 'RubricPresetView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "evaluations2.rubricpreset",
                true,
                "Modelos de Pauta"
              )
            }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              institution &&
              institution.show_study_environment_menu &&
              user &&
              (![7].includes(user.groups[0]) || user.is_superuser)
            "
            :to="{ name: 'StudyEnvironmentView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "mesh.studyenvironment",
                true,
                "Ambientes de Aprendizajes"
              )
            }}</b-dropdown-item
          >
          <b-dropdown-item
            v-if="user && (![7].includes(user.groups[0]) || user.is_superuser)"
            :to="{ name: 'ActTypeView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{ $getVisibleNames("cideu.acttype", true, "Tipos de Actas") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              ((institution && institution.internal_use_id == 'duoc_uc') ||
                $debug_change_duoc) &&
              user &&
              (![7].includes(user.groups[0]) || user.is_superuser)
            "
            :to="{ name: 'CapacitiesView' }"
          >
            <b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{ $getVisibleNames("mesh.capacity", true, "Capacidades") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              institution &&
              institution.show_matter_contribution &&
              user &&
              (![7].includes(user.groups[0]) || user.is_superuser)
            "
            :to="{ name: 'MatterContributionView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon>Estrategias
            Curriculares</b-dropdown-item
          >
          <b-dropdown-item
            v-if="
              institution &&
              (institution.internal_use_id == 'duoc_uc' ||
                $debug_change_duoc) &&
              user &&
              (![7].includes(user.groups[0]) || user.is_superuser)
            "
            :to="{ name: 'MatterCategoryView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("teaching.mattercategory", false, "Categoría")
            }}</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-button
          class="btn-programs fourth-drop"
          size="sm"
          variant="none"
          :to="{ name: 'CareerListView' }"
          >{{
            $getVisibleNames(
              "manual.menu.crear_programas",
              true,
              "Crear Programas"
            )
          }}</b-button
        >
        <b-nav-item-dropdown
          v-if="
            (user && user.is_superuser) ||
            (institution &&
              institution.internal_use_id == 'duoc_uc' &&
              ![6, 7].includes(user.groups[0]))
          "
          size="sm"
          class="resources-drop third-drop m-0 btn-curricular-products"
          :text="`${$getVisibleNames(
            'manual.menu.productos_curriculares',
            true,
            'Productos Curriculares'
          )}`"
          disabled
        >
          <b-dropdown-item
            v-if="user && user.is_superuser"
            :to="{ name: 'EgressProfilesList' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("mesh.egressprofile", true, "Perfiles de Egreso")
            }}</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'CompetenceTable' }">
            <b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{ $getVisibleNames("mesh.competence", true, "Competencias") }}
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'MatterListView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("mesh.egressprofilematter", true, "Asignaturas")
            }}</b-dropdown-item
          >
          <template
            v-if="
              (institution && institution.show_base_ra) || $debug_change_duoc
            "
          >
            <b-dropdown-item :to="{ name: 'RaBaseView' }"
              ><b-icon icon="chevron-right" scale="0.8"></b-icon
              >{{
                $getVisibleNames("teaching.basera", true, "RA Base")
              }}</b-dropdown-item
            >
          </template>
        </b-nav-item-dropdown>
        <div class="resources-drop-student" v-else></div>
        <PrintFab v-if="isLoged" class="noprint mt-1 ml-auto mr-0">
          <b-form-checkbox
            switch
            size="sm"
            class="mr-1 mt-1"
            v-if="visibility_crud"
            v-model="local_allows_crud"
            @change="mutateAllowsCrud"
            >Modo edición</b-form-checkbox
          >
          <!-- <b-button
            size="sm"
            variant="none"
            class="print-button"
            @click="generateReport"
          >
            <b-icon-printer></b-icon-printer>
          </b-button> -->
          <!-- Botón de Imprimir -->
          <b-dropdown
            label="Opciones"
            size="sm"
            variant="info"
            class="b-dropdown-container p-0 m-0 ml-1"
            split
            @click="generateReport"
          >
            <template #button-content>
              <b-icon-printer class="icon-print-navbar"></b-icon-printer>
            </template>
            <b-dropdown-form style="width: 210px" class="pb-0 pt-0 pl-1 pr-1">
              <b-form-group>
                <span class="options-print-range">Orientación al Imprimir</span>
                <b-form-checkbox
                  class="options-print mt-1"
                  name="print-vertical"
                  switch
                  v-model="local_print_orientation"
                  @change="updatePrintOrientation"
                >
                  <span v-if="local_print_orientation">
                    <b-icon icon="tablet-landscape" class="mt-1"></b-icon>
                    Horizontal
                  </span>
                  <span v-else>
                    <b-icon icon="tablet" class="mt-1"></b-icon>
                    Vertical
                  </span>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                label="Zoom al Imprimir"
                class="mt-2 options-print-range"
              >
                <b-input-group>
                  <b-form-input
                    type="range"
                    v-model="local_print_zoom_scale"
                    :min="0.3"
                    :max="1.5"
                    :step="0.05"
                    @input="updatePrintZoom"
                  ></b-form-input>
                  <b-input-group-append is-text class="text-monospace">
                    {{ (local_print_zoom_scale * 100).toFixed(0) }}%
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-dropdown-form>
          </b-dropdown>
        </PrintFab>
      </b-nav>
      <PrintFab
        :responsive="true"
        v-if="isLoged"
        class="noprint mt-1 responsive-print-fab"
      >
        <b-form-checkbox
          switch
          size="sm"
          class="mr-1 mt-1"
          v-if="visibility_crud"
          v-model="local_allows_crud"
          @change="mutateAllowsCrud"
          >Editar Formulario</b-form-checkbox
        >
        <!-- <b-button
          size="sm"
          variant="none"
          class="print-button"
          @click="generateReport"
        >
          <b-icon-printer></b-icon-printer>
        </b-button> -->
        <!-- Botón de Imprimir -->
        <b-dropdown
          label="Opciones"
          size="sm"
          variant="info"
          class="b-dropdown-container-responsive p-0 m-0 ml-1"
          split
          @click="generateReport"
        >
          <template #button-content>
            <b-icon-printer class="icon-print-navbar"></b-icon-printer>
          </template>
          <b-dropdown-form style="width: 210px" class="pb-0 pt-0 pl-1 pr-1">
            <b-form-group>
              <span class="options-print-range">Orientación al Imprimir</span>
              <b-form-checkbox
                class="options-print mt-1"
                name="print-vertical"
                switch
                v-model="local_print_orientation"
                @change="updatePrintOrientation"
              >
                <span v-if="local_print_orientation">
                  <b-icon icon="tablet-landscape" class="mt-1"></b-icon>
                  Horizontal
                </span>
                <span v-else>
                  <b-icon icon="tablet" class="mt-1"></b-icon>
                  Vertical
                </span>
              </b-form-checkbox>
            </b-form-group>
            <b-form-group
              label="Zoom al Imprimir"
              class="mt-2 options-print-range"
            >
              <b-input-group>
                <b-form-input
                  type="range"
                  v-model="local_print_zoom_scale"
                  :min="0.5"
                  :max="1.5"
                  :step="0.1"
                  @input="updatePrintZoom"
                ></b-form-input>
                <b-input-group-append is-text class="text-monospace">
                  {{ (local_print_zoom_scale * 100).toFixed(0) }}%
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-dropdown-form>
        </b-dropdown>
      </PrintFab>
      <!-- Vista Mobile -->
      <b-button
        class="responsive-nav-btn modern-nav-btn"
        variant="none"
        @click="menuExpand"
        @click.stop
      >
        <b-icon
          v-if="expanded"
          class="responsive-nav-icon"
          icon="caret-up-fill"
          scale="1.2"
        ></b-icon>
        <b-icon
          v-else
          class="responsive-nav-icon"
          icon="caret-down-fill"
          scale="1.2"
        ></b-icon>
      </b-button>
      <!-- <ToolbarAi :responsive_style="true"></ToolbarAi> -->
      <div
        ref="menuContainer"
        class="accordion-nav"
        :class="{ 'accordion-expanded': expanded }"
      >
        <!-- Definiciones Organizacionales -->
        <b-card no-body class="modern-accordion mb-1 mt-2">
          <b-card-header header-tag="header" class="p-0" role="tab">
            <b-button
              ref="btn1CollapseLoged"
              class="modern-accordion-btn"
              @click="changeStateMobileMenu(1)"
              variant="none"
              size="sm"
              v-b-toggle.accordion-1
              >Definiciones Organizacionales
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body
              v-if="user && ([1].includes(user.groups[0]) || user.is_superuser)"
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'RegionView' }"
                @click="closeMenu"
                >{{
                  $getVisibleNames("common.region", true, "Región")
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="user && ([1].includes(user.groups[0]) || user.is_superuser)"
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'CommuneView' }"
                @click="closeMenu"
                >{{
                  $getVisibleNames("common.commune", true, "Communa")
                }}</b-button
              >
            </b-card-body>
            <b-card-body>
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'CampusListView' }"
                @click="closeMenu"
                >{{ $getVisibleNames("mesh.campus", true, "Sedes") }}</b-button
              >
            </b-card-body>
            <b-card-body>
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'FacultyListView' }"
                @click="closeMenu"
                >{{
                  $getVisibleNames("mesh.faculty", true, "Facultades")
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                user && (![6, 7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'PeriodView' }"
                @click="closeMenu"
                >Períodos Académicos</b-button
              >
            </b-card-body>
            <b-card-body v-if="user && user.is_superuser">
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'TimeAllocationView' }"
                @click="closeMenu"
                >{{
                  $getVisibleNames(
                    "mesh.timeallocation",
                    true,
                    "Divisiones de tiempo"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body>
              <b-button
                v-if="
                  user &&
                  (![6, 7].includes(user.groups[0]) || user.is_superuser) &&
                  ((institution && institution.internal_use_id == 'duoc_uc') ||
                    $debug_change_duoc)
                "
                class="modern-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'GenericCareerView' }"
                @click="closeMenu"
                >{{
                  $getVisibleNames(
                    "mesh.genericcareer",
                    true,
                    "Carrera Genérica"
                  )
                }}</b-button
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- Definiciones Curriculares -->
        <b-card no-body class="modern-accordion mb-1">
          <b-card-header header-tag="header" class="p-0" role="tab">
            <b-button
              ref="btn2CollapseLoged"
              class="modern-accordion-btn"
              @click="changeStateMobileMenu(2)"
              v-b-toggle.accordion-2
              variant="none"
              size="sm"
              >Definiciones Curriculares</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body
              v-if="
                user && (![7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'EgressProfileTypeView' }"
                @click="closeMenu"
                >{{
                  $getVisibleNames(
                    "mesh.egressprofiletype",
                    true,
                    "Tipos de Perfiles de Egreso"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                user && (![7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'ModalityEgressProfileView' }"
                @click="closeMenu"
                >{{
                  $getVisibleNames(
                    "mesh.modalityegressprofile",
                    true,
                    "Modalidad del Perfil de Egreso"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                !(
                  (institution && institution.internal_use_id == 'duoc_uc') ||
                  $debug_change_duoc
                ) &&
                user &&
                (![7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'CompetenceTypeView' }"
                @click="closeMenu"
              >
                {{
                  $getVisibleNames(
                    "mesh.competencetype",
                    true,
                    "Tipos de Competencias"
                  )
                }}
              </b-button>
            </b-card-body>
            <b-card-body
              v-if="
                !(
                  (institution && institution.internal_use_id == 'duoc_uc') ||
                  $debug_change_duoc
                ) &&
                user &&
                (![7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'CyclesListView' }"
                @click="closeMenu"
                >{{
                  $getVisibleNames("mesh.cycle", true, "Ciclos Formativos")
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                user && (![7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'FormationAreaView' }"
                @click="closeMenu"
                >{{
                  $getVisibleNames(
                    "mesh.formationarea",
                    true,
                    "Áreas De Formación"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                user && (![7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'FormationLineView' }"
                @click="closeMenu"
              >
                {{
                  $getVisibleNames(
                    "mesh.formationline",
                    true,
                    "Líneas de Formación"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                user && (![7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'MatterTypeView' }"
                @click="closeMenu"
              >
                {{
                  $getVisibleNames(
                    "mesh.mattertype",
                    true,
                    "Tipos de Asignatura"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                user && (![7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'ModalityView' }"
                @click="closeMenu"
                >{{
                  $getVisibleNames(
                    "mesh.modality",
                    false,
                    "Modalidad de Asignaturas"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                user && (![7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'BaseMattersWrapperView' }"
                @click="closeMenu"
                >{{
                  $getVisibleNames(
                    "mesh.basematterwrapper",
                    true,
                    "Envoltorio de Asignaturas"
                  )
                }}
              </b-button>
            </b-card-body>
            <b-card-body>
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'CertificationView' }"
                @click="closeMenu"
                >{{
                  $getVisibleNames(
                    "mesh.certification",
                    true,
                    "Certificación de Credenciales"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                user && (![7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'EvaluationSituationView' }"
                @click="closeMenu"
                >{{
                  $getVisibleNames(
                    "teaching.evaluationsituation",
                    true,
                    "Situación Evaluativas"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                institution &&
                institution.kl_assessment &&
                user &&
                ([1].includes(user.groups[0]) || user.is_superuser) &&
                (![7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'RubricPresetView' }"
                @click="closeMenu"
              >
                {{
                  $getVisibleNames(
                    "evaluations2.rubricpreset",
                    true,
                    "Modelos de Pauta"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                institution &&
                institution.show_study_environment_menu &&
                user &&
                (![7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'StudyEnvironmentView' }"
                @click="closeMenu"
                >{{
                  $getVisibleNames(
                    "mesh.studyenvironment",
                    true,
                    "Ambientes de Aprendizajes"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                user && (![7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'ActTypeView' }"
                @click="closeMenu"
                >{{
                  $getVisibleNames("cideu.acttype", true, "Tipos de Actas")
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                ((institution && institution.internal_use_id == 'duoc_uc') ||
                  $debug_change_duoc) &&
                user &&
                (![7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'CapacitiesView' }"
                @click="closeMenu"
              >
                {{ $getVisibleNames("mesh.capacity", true, "Capacidad") }}
              </b-button>
            </b-card-body>
            <b-card-body
              v-if="
                institution &&
                institution.show_matter_contribution &&
                user &&
                (![7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'MatterContributionView' }"
                @click="closeMenu"
                >Estrategias Curriculares</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                institution &&
                (institution.internal_use_id == 'duoc_uc' ||
                  $debug_change_duoc) &&
                user &&
                (![7].includes(user.groups[0]) || user.is_superuser)
              "
            >
              <b-button
                class="modern-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'MatterCategoryView' }"
                @click="closeMenu"
                >{{
                  $getVisibleNames(
                    "teaching.mattercategory",
                    true,
                    "Categorías"
                  )
                }}
              </b-button>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Productos Curriculares -->
        <b-card
          no-body
          v-if="
            (user && user.is_superuser) ||
            (institution &&
              institution.internal_use_id == 'duoc_uc' &&
              ![6, 7].includes(user.groups[0]))
          "
          class="modern-accordion mb-1"
        >
          <b-card-header header-tag="header" class="p-0" role="tab">
            <b-button
              ref="btn3CollapseLoged"
              class="modern-accordion-btn"
              @click="changeStateMobileMenu(3)"
              v-b-toggle.accordion-3
              variant="none"
              size="sm"
              >{{
                $getVisibleNames(
                  "manual.menu.productos_curriculares",
                  true,
                  "Productos Curriculares"
                )
              }}
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'CompetenceTable' }"
                @click="closeMenu"
                >Listado Total de
                {{ $getVisibleNames("mesh.competence", true, "Competencias") }}
              </b-button>
            </b-card-body>
            <b-card-body>
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'MatterListView' }"
                @click="closeMenu"
                >Listado Total de
                {{
                  $getVisibleNames(
                    "mesh.egressprofilematter",
                    true,
                    "Asignaturas"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body v-if="user && user.is_superuser">
              <b-button
                class="modern-nav-link mb-1"
                variant="light"
                size="sm"
                :to="{ name: 'EgressProfilesList' }"
                @click="closeMenu"
                >Registro de
                {{
                  $getVisibleNames(
                    "mesh.egressprofile",
                    true,
                    "Perfiles de Egreso"
                  )
                }}</b-button
              >
            </b-card-body>
            <template
              v-if="
                (institution && institution.show_base_ra) || $debug_change_duoc
              "
            >
              <b-card-body>
                <b-button
                  class="modern-nav-link"
                  variant="light"
                  size="sm"
                  :to="{ name: 'RaBaseView' }"
                  @click="closeMenu"
                  >{{
                    $getVisibleNames("teaching.basera", true, "RA Base")
                  }}</b-button
                >
              </b-card-body>
            </template>
          </b-collapse>
        </b-card>

        <!-- Programas -->
        <b-card no-body class="modern-accordion mb-1">
          <b-card-header header-tag="header" class="p-0" role="tab">
            <b-button
              ref="btn4CollapseLoged"
              class="modern-accordion-btn"
              @click="changeStateMobileMenu(4)"
              v-b-toggle.accordion-4
              variant="none"
              size="sm"
            >
              Registro de Programas
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-button
                class="modern-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'CareerListView' }"
                @click="closeMenu"
              >
                {{
                  $getVisibleNames(
                    "manual.menu.crear_programas",
                    true,
                    "Crear Programas"
                  )
                }}
              </b-button>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/utils/utils";
import { APP_NAME } from "@/utils/globals";
import { mapGetters } from "vuex";
import * as names from "@/store/names";
import Vue from "vue";
import VueClosable from "vue-closable";
Vue.use(VueClosable);

export default {
  name: "NavBar",
  components: {
    HelpComponent: () => import("@/components/reusable/HelpComponent"),
    PrintFab: () => import("@/components/reusable/PrintFab"),
    // ToolbarAi: () => import("@/components/public/ToolbarAi"),
  },
  data() {
    return {
      sisera_logo: require("@/assets/new-page/KEY_LEARNING_LOGO.svg"),
      gera_logo: require("@/assets/logo_gera.png"),
      welcomeMessage: "",
      loading: false,
      loading_value: 0,
      loading_max: 0,
      app_name: APP_NAME,
      login_redirect: false,
      expanded: false,
      expanded1: false,
      expanded2: false,
      expanded3: false,
      expanded4: false,
      local_allows_crud: JSON.parse(localStorage.getItem("allows_crud")),
      local_print_orientation: false,
      previous_print_orientation: false,
      local_print_zoom_scale: 1,
      previous_print_zoom_scale: 1,
      local_title_of_the_print: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      isLoged: "isLoged",
      institution: "getInstitution",
      institution_logo: "getInstitutionLogo",
      images: names.IMAGES,
      allows_crud: names.ALLOWS_CRUD,
      visibility_crud: names.VISIBILITY_CRUD,
      print_zoom_scale: names.PRINT_ZOOM_SCALE,
      print_orientation: names.PRINT_ORIENTATION,
      title_of_the_print: names.TITLE_OF_THE_PRINT,
    }),
    logo() {
      if (this.app_name == "GERA") return this.gera_logo;
      return this.sisera_logo;
    },
    userName() {
      let username = "Usuario";
      if (this.user) {
        username = this.user.email;
        if (this.user.first_name) username = this.user.first_name;
        if (this.user.last_name)
          username = this.user.first_name + " " + this.user.last_name;
      }
      return username;
    },
  },
  methods: {
    changeStateMobileMenu(btn_number) {
      if (btn_number == 1) this.expanded1 = !this.expanded1;
      else this.expanded1 = false;
      if (btn_number == 2) this.expanded2 = !this.expanded2;
      else this.expanded2 = false;
      if (btn_number == 3) this.expanded3 = !this.expanded3;
      else this.expanded3 = false;
      if (btn_number == 4) this.expanded4 = !this.expanded4;
      else this.expanded4 = false;
    },
    menuExpandOutside() {
      this.expanded = !this.expanded;
    },
    closeMenu() {
      //Metodo para cerrar el menu cuando se hace click en un boton del menú
      this.expanded = !this.expanded;
      this.closeSubmenus();
    },
    closeMenuNoLoged() {
      this.expanded = !this.expanded;
    },
    closeMenuNoLoged2() {
      this.expanded = false;
    },
    menuExpand() {
      this.expanded = !this.expanded;
      if (!this.expanded) {
        // Si se cierra el menú, se asegura de cerrar los submenús
        this.closeSubmenus();
        this.removeClickOutsideListener();
      } else {
        // Agrega el listener cuando el menú se expande
        this.addClickOutsideListener();
      }
    },
    closeSubmenus() {
      if (this.expanded1)
        this.$root.$emit("bv::toggle::collapse", "accordion-1");
      if (this.expanded2)
        this.$root.$emit("bv::toggle::collapse", "accordion-2");
      if (this.expanded3)
        this.$root.$emit("bv::toggle::collapse", "accordion-3");
      if (this.expanded4)
        this.$root.$emit("bv::toggle::collapse", "accordion-4");

      this.expanded1 = false;
      this.expanded2 = false;
      this.expanded3 = false;
      this.expanded4 = false;
    },
    handleClickOutside(event) {
      if (!this.$refs.menuContainer.contains(event.target)) {
        this.menuExpand(); // Cierra el menú logueado
      }
    },
    addClickOutsideListener() {
      document.addEventListener("click", this.handleClickOutside);
    },
    removeClickOutsideListener() {
      document.removeEventListener("click", this.handleClickOutside);
    },
    mutateAllowsCrud() {
      if (this.local_allows_crud != this.allows_crud) {
        this.$store.commit(names.MUTATE_ALLOWS_CRUD, this.local_allows_crud);
      }
    },
    generateReport() {
      if (!this.local_allows_crud || !this.visibility_crud) {
        window.print();
      } else
        this.$swal({
          title: `Debe salir del modo edición antes de poder imprimir.`,
          type: "warning",
          showCancelButton: false,
        });
    },
    handleBeforePrint() {
      // Antes de imprimir
      document.title = APP_NAME;
      if (this.institution) document.title = this.institution.name;
      if (this.local_title_of_the_print != null)
        document.title = this.local_title_of_the_print;
    },
    handleAfterPrint() {
      // Después de imprimir
      document.title = APP_NAME;
    },
    updatePrintStyles(orientation) {
      const style = document.createElement("style");
      style.setAttribute("id", "print-styles");
      style.innerHTML = `
        @media print {
          @page {
            size: ${orientation};
          }
          body {
            zoom: ${this.local_print_zoom_scale};
          }
        }
      `;
      const existingStyle = document.getElementById("print-styles");
      if (existingStyle) {
        existingStyle.parentNode.removeChild(existingStyle);
      }
      document.head.appendChild(style);
    },
    updatePrintOrientation() {
      const orientation = this.local_print_orientation
        ? "landscape"
        : "portrait";
      this.updatePrintStyles(orientation);
    },
    updatePrintZoom() {
      this.$store.commit(
        names.MUTATE_PRINT_ZOOM_SCALE,
        this.local_print_zoom_scale
      );
      this.updatePrintStyles(
        this.local_print_orientation ? "landscape" : "portrait"
      );
    },
  },
  watch: {
    print_zoom_scale(value) {
      if (value == 0) {
        this.local_print_zoom_scale = this.previous_print_zoom_scale;
        this.updatePrintStyles(
          this.local_print_orientation ? "landscape" : "portrait"
        );
      } else if (value > 0) {
        this.previous_print_zoom_scale = this.local_print_zoom_scale;
        this.local_print_zoom_scale = value;
        this.updatePrintStyles(
          this.local_print_orientation ? "landscape" : "portrait"
        );
      }
    },
    title_of_the_print(value) {
      this.local_title_of_the_print = value;
    },
    print_orientation(value) {
      if (value === null) {
        this.local_print_orientation = this.previous_print_orientation;
        this.updatePrintStyles(
          this.local_print_orientation ? "landscape" : "portrait"
        );
      } else if (value != null && (value === true || value === false)) {
        this.previous_print_orientation = this.local_print_orientation;
        this.local_print_orientation = value;
        this.updatePrintStyles(
          this.local_print_orientation ? "landscape" : "portrait"
        );
      }
    },
    isLoged(value) {
      if (!value) this.local_allows_crud = false;
    },
  },
  beforeDestroy() {
    window.removeEventListener("beforeprint", this.handleBeforePrint);
    window.removeEventListener("afterprint", this.handleAfterPrint);
    this.removeClickOutsideListener();
  },
  mounted() {
    window.addEventListener("beforeprint", this.handleBeforePrint);
    window.addEventListener("afterprint", this.handleAfterPrint);
  },
  created() {
    this.updatePrintStyles("portrait");
    EventBus.$on("loadStarted", () => {
      this.loading = true;
    });
    EventBus.$on("loadFinished", () => {
      this.loading = false;
    });
    EventBus.$on("increment", (max) => {
      this.loading_max = max;
    });
    EventBus.$on("decrement", (value) => {
      this.loading_value = value;
    });
  },
};
</script>

<style scoped>
.modern-accordion {
  border: none;
  background: #6cb2c6 !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.modern-accordion-btn {
  font-weight: bold;
  padding: 5px;
  text-align: center;
  width: 100%;
  background: none;
}

.modern-nav-link {
  color: #007bff;
  font-weight: bold;
  padding: 3px;
  text-align: left;
  width: 100%;
  background-color: #f8f9fa;
  border-radius: 0px !important;
}
.modern-nav-btn {
  background-color: #ffffff;
  color: white !important;
  padding: 5px 0px 5px 10px;
  margin: 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.img-thumbnail {
  border: 0px;
}
.button-content {
  color: #01adb7;
  opacity: 1;
}
.dropdown-adjustment >>> .dropdown-menu {
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}
.user-button:hover {
  color: white;
  opacity: 0.9;
}
.user-button {
  display: flex;
  background-color: #01adb7;
  width: 11em;
  height: 4em;
  color: white;
  border-radius: 55px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.user-button-left {
  text-align: left;
}
.user-button-right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #006ec8;
  border-top-right-radius: 55px;
  border-bottom-right-radius: 55px;
}
.user-info-container {
  width: auto;
  height: 100%;
  font-size: 15px;
  margin-left: auto;
  margin-right: 3%;
  transition: 0.2s;
}
.navbar {
  background-color: white;
  box-shadow: 0 1.8px 3px -2px rgb(0, 0, 0);
  position: fixed;
  height: 4.1em;
}
.navbar-nav {
  float: left;
  white-space: nowrap !important;
}
.responsive-bar {
  display: none;
}
.current-view {
  color: #01adb7 !important;
}
.landing-nav {
  height: 80px;
  width: 100%;
  align-items: center;
  padding: 0 2px;
  flex-wrap: wrap;
  padding-bottom: 7%;
  margin-left: -18%;
  margin-right: 6%;
}
.navbar-nav >>> .nav-link {
  color: #a5a5a5 !important;
  max-height: 40px;
  cursor: pointer;
  margin-left: 2em;
}
.navbar-nav >>> .nav-link:hover {
  background: white;
  color: #01adb7;
  border-radius: 7px;
}
.items-dropdown >>> .dropdown-menu {
  width: 90%;
  left: 12%;
  margin-top: 0 !important;
}
.landing-nav >>> .nav-items {
  display: flex;
  flex-wrap: nowrap;
  font-size: 14pt !important;
  margin-right: 15vh;
}
.landing-nav .nav-items b-nav-item {
  margin: 0 5px;
}
.landing-nav .nav-items b-nav-item {
  color: #666;
  text-decoration: none;
  font-size: 14pt !important;
  font-weight: 500;
  padding: 8px 6px;
  border-radius: 5px;
  letter-spacing: 1px;
  transition: all 0.2s ease;
}
.landing-nav .nav-items b-nav-item.active,
.landing-nav .nav-items b-nav-item:hover {
  color: var(--header-color);
  background: #fff;
}
.landing-nav .menu-btn i {
  color: #666;
  font-size: 28px;
  cursor: pointer;
  display: none;
}
.nav-items >>> .nav-link {
  margin-left: 2em !important;
}
.brand-text {
  font-family: "Source Sans Pro", sans-serif;
  justify-content: start;
  margin-left: 10px;
  padding-left: 10px;
  position: relative;
  margin-right: -15% !important;
  font-size: var(--secondary-font-size) - 2;
}
.dropdown-item {
  margin: 0px;
  padding: 0px;
  color: white;
  background-color: transparent;
}
.not-visible {
  display: none !important;
}
.logo {
  margin-left: 1em !important;
  max-width: 200px;
  max-height: 60px;
  min-height: 60px;
  min-width: 60px;
}
.searchInput {
  margin: 4px;
}
.loadingBar {
  position: fixed;
  top: 61px;
  left: 0;
  right: 0;
  width: 100%;
  margin-top: 30px;
}
.not-logged-in {
  position: fixed;
  top: 16px !important;
  left: 0;
  right: 0;
  width: 100%;
  margin-top: 0;
}
.loadingBar >>> .progress {
  z-index: 900 !important;
  border-radius: 0;
}
.user-name {
  color: #01adb7;
  opacity: 1;
  font-size: 14pt;
  padding-right: 0.5em;
}
.user-icon {
  color: #01adb7;
  opacity: 1;
}
.user-icon2 {
  color: white;
}
.profile-section {
  right: 0 !important;
  margin-left: 24%;
}
.profile-section:hover {
  cursor: pointer;
  background: var(--header-hover-color);
  border-radius: 6px;
}
.right-setting {
  margin-right: 0px !important;
}
.profile-section >>> .dropdown-menu {
  transform: translateY(-54%);
  right: 0;
  left: -25%;
}
.sign-in {
  height: 70px;
}

.mobile-nav {
  display: none;
}
.btn-collapse-mobile {
  background: transparent !important;
  border: none !important;
  color: #a5a5a5;
}
.card-body {
  padding: 0;
}
.nav-btn {
  z-index: 2000 !important;
  background: rgb(245, 245, 245);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: fixed;
  z-index: 1000;
  top: 4rem;
  left: 0;
  width: 100%;
}
.navigation-drop {
  color: #fff;
  background: #01adb7 !important;
}
.navigation-drop:hover,
.navigation-link:hover {
  background: var(--header-hover-color) !important;
  border-color: #01adb7;
}
.navigation-item {
  display: block;
  background: rgb(245, 245, 245);
  margin: 2px;
}
.navigation-item a {
  color: var(--secondary-font-color);
}
.navigation-item:hover {
  background: var(--header-hover-color);
}
.navigation-item:hover a {
  color: white;
}
.navigation-link {
  width: 98%;
  color: #fff;
  background: #01adb7 !important;
  height: min-content;
}
.shown {
  display: block !important;
}
.navbar-nav >>> .nav-link {
  margin-left: 0;
}
.resources-drop-student {
  background: #ffb700;
  max-height: 32px !important;
  min-width: 20%;
  padding-top: 0.1em;
}
.resources-drop {
  flex-wrap: wrap;
  max-height: 32px !important;
  width: 20%;
  font-weight: bold;
  padding-top: 0.1em;
  color: #fff !important;
}
.resources-drop >>> .disabled {
  color: #fff !important;
}
.resources-drop:hover,
.resources-drop:hover >>> .disabled {
  background: rgb(225, 225, 225);
  cursor: pointer;
  max-height: 31px;
}
.resources-drop-item {
  width: 400px !important;
}
.resources-drop >>> .dropdown-item {
  min-width: 19em !important;
  background: var(--kl-menu-color);
  color: #fff;
}
.resources-drop >>> .dropdown-item:hover {
  background: var(--kl-menu-hover-color);
}
.resources-drop >>> .dropdown-menu {
  padding: 0 !important;
}
ul >>> .dropdown-menu li {
  margin-left: 0 !important;
}
.first-drop {
  background-color: var(--kl-menu-color) !important;
  color: #fff !important;
  transition: all 0.2s;
}
.first-drop:hover >>> .disabled {
  background-color: var(--kl-menu-hover-color) !important;
  transition: all 0.2s;
}
.second-drop {
  background-color: var(--kl-menu-color) !important;
}
.second-drop:hover >>> .disabled {
  background-color: var(--kl-menu-hover-color) !important;
  transition: all 0.2s;
}
.third-drop {
  background-color: var(--kl-menu-color) !important;
}
.third-drop:hover >>> .disabled {
  background-color: var(--kl-menu-hover-color) !important;
  transition: all 0.2s;
}
.fourth-drop {
  background-color: var(--kl-fourth-color) !important;
}
.fourth-drop:hover >>> .disabled {
  background-color: var(--kl-menu-hover-color) !important;
  transition: all 0.2s;
}
.brand-section {
  margin-left: -1.5%;
  margin-right: 1% !important;
}
.brand-text {
  margin-left: -1%;
}
.b-nav-section {
  width: 100%;
  max-height: 32px !important;
  font-size: 10pt;
  background: var(--kl-menu-color);
  position: fixed;
  padding-left: 1%;
  top: 4.4em;
  z-index: 1000 !important;
  box-shadow: 0 0.5px 4px -1px rgb(145, 145, 145);
}
.responsive-nav-btn {
  display: none;
}
.accordion-nav {
  display: none;
}
.btn-programs {
  font-size: var(--secondary-font-size);
  font-weight: bold;
  /* transform: translateY(8%); */
  color: #fff !important;
  max-height: 32px;
  width: 17.8%;
  padding: 0 1.05em !important;
  padding-top: 0.6em !important;
  border-radius: 0;
}
.btn-programs:focus {
  outline: none;
  box-shadow: none;
  background-color: var(--kl-fourth-hover-color) !important;
  transition: all 0.2s;
  border-radius: 0;
}
.btn-programs:hover {
  background-color: var(--kl-fourth-hover-color) !important;
  transition: all 0.2s;
}
.btn-curricular-products {
  font-size: var(--secondary-font-size);
  background-color: #ffb700 !important;
  font-weight: bold;
  color: #fff !important;
}
.btn-curricular-products:focus {
  outline: none;
  box-shadow: none;
  background-color: #ffb700 !important;
  transition: all 0.2s;
  border-radius: 0;
}
.btn-curricular-products:hover {
  background-color: #ffb700 !important;
  transition: all 0.2s;
}
.nav li:hover >>> .dropdown-menu {
  display: block;
  margin-top: 0;
}
.b-dropdown-form {
  padding: 0px 3px 0px 3px !important;
}
.b-dropdown-container {
  width: 27px;
  height: 27px;
}
.b-dropdown-container-responsive {
  width: 26px;
  height: 26px;
  margin-right: 2rem !important;
}
.icon-print-navbar {
  margin-top: 2px;
}
.options-print {
  font-size: 10pt;
}
.input-group-text {
  padding: 2px !important;
}
.options-print-range {
  font-size: 10pt;
  font-weight: bold;
}
.print-button {
  color: white !important;
  width: 25px;
  height: 25px;
}
.print-button:hover {
  background: #62abc1;
}
.print-button > .b-icon {
  transform: translateX(-20%);
}
.responsive-print-fab {
  display: none;
}
.help-icon {
  padding-top: 3px !important;
}
/** ----------- Media Queries ----------- **/
@media (max-width: 1200px) {
  .help-icon {
    padding-top: 0px !important;
  }
  .user-info-container {
    margin-right: 1%;
  }
  .landing-nav {
    margin-right: 1%;
    margin-left: 10%;
  }
  .profile-section {
    margin-left: 1%;
  }
  .print-button {
    margin-left: 0;
  }
  .print-button {
    width: 26px !important;
    height: 26px !important;
  }
  .responsive-nav-btn {
    width: 27px !important;
    height: 25px !important;
    margin-right: 5px !important;
  }
  .responsive-nav-icon {
    transform: translate(-39%, -13%) !important;
  }
  .print-button {
    width: 26px !important;
    height: 26px !important;
  }
  .responsive-nav-icon {
    transform: translate(-39%, -13%) !important;
  }
}

@media only screen and (min-width: 897px) and (max-width: 1400px) {
  .help-icon {
    padding-top: 3px !important;
  }
  .brand-text {
    font-size: var(--secondary-font-size) - 2;
  }
  .searchForm {
    display: none;
  }
  /* .logo {
    width: 8em;
    height: 8em;
  } */
  .right-setting {
    margin-right: 1% !important;
  }
  .user-name {
    font-size: 13pt;
  }
  .profile-section {
    margin-left: 1%;
    margin-bottom: 0;
  }
  .landing-nav >>> .nav-items {
    font-size: 13pt !important;
  }
  .landing-nav {
    margin-left: 10%;
    margin-right: -8%;
    padding-bottom: 10% !important;
  }
  .brand-section {
    transform: translateX(-1em);
    margin-left: 1%;
    margin-right: -20% !important;
  }
}

@media only screen and (min-width: 701px) and (max-width: 980px) {
  .help-icon {
    padding-top: 0px !important;
  }
  .user-button {
    width: 4em;
  }
  .user-button-left {
    display: none;
  }
  .user-button-right {
    border-radius: 55px;
  }
  .brand-text {
    display: none;
  }
  .right-setting {
    margin-right: 1% !important;
  }
  .searchForm {
    display: none;
  }
  /* .logo {
    width: 8em;
    height: 8em;
  } */
  .user-name {
    display: none;
  }
  .profile-section {
    margin-left: 1%;
    margin-right: 1%;
  }
  .dropdown-adjustment >>> .dropdown-menu {
    right: 0 !important;
    left: -500%;
  }
  .profile-section >>> .dropdown-menu {
    right: 0;
    left: -22% !important;
  }
  .landing-nav {
    margin-left: 0;
    margin-right: -10%;
  }
  .landing-nav >>> .nav-link {
    font-size: var(--secondary-font-size);
  }
  .landing-nav >>> .nav-items {
    margin-right: 1vh;
    padding-bottom: 10%;
    display: none;
  }
  .mobile-nav {
    display: block;
    background-color: #01adb7;
  }
  .resources-b-nav {
    display: none;
  }
  .responsive-nav-btn {
    display: block;
    float: right;
    width: 30px;
    height: 29px;
    margin-top: 0.4em;
    margin-bottom: 0.1em;
    margin-right: 0.1em;
    transition: all 0.3s;
    background: #50a1ba !important;
    border: var(--kl-menu-hover-color) !important;
    color: var(--secondary-font-color) !important;
  }
  .responsive-nav-btn:focus {
    border: none;
  }
  .responsive-nav-btn:hover {
    background: #62abc1 !important;
  }
  .responsive-nav-icon {
    /* transform: translate(-15%, 10%); */
    text-align: center;
    vertical-align: middle;
  }
  .responsive-print-fab {
    display: block;
  }
  .nav-expanded {
    max-height: 800px !important;
    transition: 0.2s !important;
  }
  .accordion-nav {
    display: block;
    margin-inline: 10%;
    margin-top: -70% !important;
    transition: 0.2s;
  }
  .accordion-expanded {
    margin-top: 0% !important;
    z-index: 2000 !important;
    /* transition: .4s ease-in-out; */
  }
  .b-nav-section {
    transition: 0.1s !important;
  }
}
@media only screen and (max-width: 700px) {
  .help-icon {
    padding-top: 0px;
  }
  .user-button {
    width: 4em;
    margin-right: 10px;
  }
  .user-button-left {
    display: none;
  }
  .user-button-right {
    border-radius: 55px;
  }
  .brand-text {
    display: none;
  }
  .searchForm {
    display: none;
  }
  /* .logo {
    width: 8em;
    height: 8em;
  } */
  .user-name {
    display: none;
  }
  .nav-items {
    font-size: var(--thirth-font-size) !important;
  }
  .landing-nav {
    margin-left: 0;
    margin-right: -10%;
  }
  .landing-nav >>> .nav-link {
    font-size: var(--secondary-font-size);
  }
  .landing-nav >>> .nav-items {
    margin-right: 1vh;
    padding-bottom: 10%;
    display: none;
  }
  .mobile-nav {
    display: block;
    background-color: #01adb7;
  }
  /* .brand-section >>> .logo {
    min-width: 45px !important;
  } */
  .resources-b-nav {
    display: none;
  }
  .responsive-nav-btn {
    display: block;
    float: right;
    width: 25px;
    height: 25px;
    margin-top: 0.4em;
    margin-bottom: 0.1em;
    margin-right: 0.4em;
    background: #50a1ba !important;
    border: var(--kl-menu-hover-color) !important;
    color: var(--secondary-font-color) !important;
  }
  .responsive-nav-btn:hover {
    background: #62abc1 !important;
  }
  .responsive-nav-icon {
    transform: translate(-25%, -10%);
  }
  .nav-expanded {
    max-height: fit-content !important;
    transition: 0.2s linear;
    overflow: hidden;
  }
  .accordion-nav {
    display: block;
    margin-right: 11%;
    margin-left: 6%;
    margin-top: -70% !important;
    transition: 0.3s;
  }
  .accordion-expanded {
    margin-top: 0% !important;
    z-index: 2000 !important;
  }
  .dropdown-adjustment >>> .dropdown-menu {
    right: 0 !important;
    left: -500%;
  }
}
@media only screen and (max-width: 620px) {
  .help-icon {
    padding-top: 0px;
  }
  .user-button {
    width: 4em;
    margin-right: 10px;
  }
  .user-button-left {
    display: none;
  }
  .user-button-right {
    border-radius: 55px;
  }
  .user-name {
    display: none;
  }
  .nav-items {
    font-size: var(--thirth-font-size) !important;
  }
  .landing-nav >>> .nav-link {
    font-size: var(--secondary-font-size);
  }
  .profile-section {
    margin-right: 10%;
  }
  .brand-section {
    margin-right: 1% !important;
  }
  .navbar-expand .navbar-nav >>> .nav-link {
    padding: 0 !important;
  }
  .navbar-expand .navbar-nav >>> .dropdown-menu {
    font-size: 11pt;
    /* left: 1%; */
  }
  .dropdown-adjustment >>> .dropdown-menu {
    right: 0 !important;
    left: -500%;
  }
}
@media (max-width: 590px) {
  .help-icon {
    padding-top: 0px;
    margin-right: 7px;
  }
  .user-button {
    width: 4em;
    margin-right: 10px;
  }
  .user-button-left {
    display: none;
  }
  .user-button-right {
    border-radius: 55px;
  }
  .user-name {
    display: none;
  }
  .nav-items {
    font-size: var(--thirth-font-size) !important;
  }
  .profile-section {
    display: none;
  }
  .brand-section {
    margin-right: 1% !important;
  }
  .landing-nav {
    margin-left: 0;
    margin-right: -10%;
  }
  .landing-nav >>> .nav-link {
    font-size: var(--secondary-font-size);
  }
  .landing-nav >>> .nav-items {
    margin-right: 1vh;
    padding-bottom: 10%;
    display: none;
  }
  li {
    margin-left: 0;
  }
  .navbar-expand .navbar-nav >>> .nav-link {
    padding: 0 !important;
    padding-top: 0.5em !important;
  }
  .dropdown-adjustment >>> .dropdown-menu {
    right: 0 !important;
    left: -700%;
  }
  .mobile-nav {
    display: block;
    background-color: #01adb7;
  }
}
</style>